import React, { useEffect, useState } from 'react'
import StepsProgress from "./StepsProgress";
import PersonalForm from './PersonalForm';
import EducationInfo from './EducationInfo';
import WorkInFormation from './WorkInformation';
import BankInformation from './BankInformation';
import { Form, message } from 'antd';
import moment from 'moment';
import { CALL_API } from 'common/API';

function AddUser() {
  const [step, setStep] = useState(0)
  const [showLoader, toggleLoader] = useState(false);
  const [formValues, setFormValues] = useState({})
  const [form] = Form.useForm();
  const [signUniqueId, setSignUniqueId] = useState('')

  const stepdata = [
    { id: 1, title: "" },
    { id: 2, title: "" },
    { id: 3, title: "" },
    { id: 4, title: ""},
  ];

  const goBack = () => {
    setStep(step - 1)
  }

  const handleSubmit = async (values) => {
    try {
      toggleLoader(true);

      const {
        firstname,
        lastname,
        salutation,
        othernames,
        dateofbirth,
        gender,
        dependent,
        maritalstatus,
        phoneno,
        user_id,
        postaladdress,
        physicaladdress,
        country,
        greetings,
        national_id,
        nationality,
        workpermitno,
        workpermitexpirydate,
        emergencycontactname,
        emergencycontactnumber,
        nextofkinrelationship,
        nextofkinpostaladdress,
        educationdetails,
        practicaltrainings,
        professionalcertifications,
        workexperience,
        accountname,
        accountno,
        bankname,
        bankbranch,
        bankcountry,
        bankcode,
        bankbranchcode,
        bankswiftcode,
        nssf,
        krapin,
        nhif,
        helpmonthlybilling,
        signdate
      } = values;

      let userId = form.getFieldValue("user_id");
      let splitUserId = userId.split("@");
      let userIdWithoutDomain = splitUserId[0];
      const dobDate = new Date(dateofbirth);
      const currentDate = new Date();
      const agecalculte = currentDate.getFullYear() - dobDate.getFullYear();

      const workexperienceDetails = workexperience?.map((item) => {
        const { fromdate, todate, ...rest } = item;
        const formattedFromDate =  fromdate ? moment(fromdate).format("YYYY-MM-DD") : null;
        const formattedToDate = todate ? moment(todate).format("YYYY-MM-DD") : null;
        return {
          ...rest,
          fromdate: formattedFromDate,
          todate: formattedToDate,
        };
      });

      const transformedEducationDetails = educationdetails?.map((item) => {
        const { attachment, fromdate, todate, ...rest } = item;
        const formattedFromDate = fromdate ? moment(fromdate).format("YYYY-MM-DD") : null;
        const formattedToDate = todate ? moment(todate).format("YYYY-MM-DD") : null;
        const updatedAttachment = attachment?.map((att) => ({
          name: att?.name,
          uniqueId: att?.response?.uniqueId,
        }));
        return {
          ...rest,
          fromdate: formattedFromDate,
          todate: formattedToDate,
          attachment: updatedAttachment,
        };
      });

      const practicaltrainingsDetails = practicaltrainings?.map((item) => {
        const { fromdate, todate, ...rest } = item;
        const formattedFromDate = fromdate ? moment(fromdate).format("YYYY-MM-DD") : null;
        const formattedToDate = todate ? moment(todate).format("YYYY-MM-DD") : null; 
        return {
          ...rest,
          fromdate: formattedFromDate,
          todate: formattedToDate,
        };
      });
      const professionalcertificationsDetails = professionalcertifications?.map(
        (item) => {
          const { attachment, fromdate, todate, ...rest } = item;
          const formattedFromDate = fromdate ? moment(fromdate).format("YYYY-MM-DD") : null;
          const formattedToDate = todate ? moment(todate).format("YYYY-MM-DD") : null;
          const updatedAttachment = attachment?.map((att) => ({
            name: att?.name,
            uniqueId: att?.response?.uniqueId,
          }));
          return {
            ...rest,
            fromdate: formattedFromDate,
            todate: formattedToDate,
            attachment: updatedAttachment,
          };
        }
      );

      const response = await CALL_API(`add-users/WASZS4S`, "post", {
        full_name: `${firstname} ${lastname}`,
        user_id: user_id,
        gender: gender,
        status_id: "2",
        // profile_url: profileUploaded
        //   ? `https://insyts-bdo-profiles-v1.s3.us-east-2.amazonaws.com/${ClientId}/${updatedUserId}/${storeId}.jpg`
        //   : null,
        nssf: nssf,
        national_id: national_id,
        phoneno: phoneno,
        physicaladdress: physicaladdress,
        statusname: "Pending",
        age: agecalculte,
        maritalstatus: maritalstatus,
        country: country,
        nationality: nationality,
        dateofbirth: dateofbirth,
        emergencycontactname: emergencycontactname,
        emergencycontactnumber: emergencycontactnumber,
        firstname: firstname,
        lastname: lastname,
        salutation: salutation,
        greetings: greetings,
        dependent: dependent,
        educationdetails: transformedEducationDetails,
        workexperience: workexperienceDetails,
        professionalcertifications: professionalcertificationsDetails,
        practicaltrainings: practicaltrainingsDetails,
        othernames: othernames,
        postaladdress: postaladdress,
        workpermitno: workpermitno,
        workpermitexpirydate: workpermitexpirydate
          ? moment(workpermitexpirydate._d).format("YYYY-MM-DD")
          : "",
        nextofkinrelationship: nextofkinrelationship,
        nextofkinpostaladdress: nextofkinpostaladdress,
        accountno: accountno,
        accountname: accountname,
        bankname: bankname,
        bankbranch: bankbranch,
        bankcountry: bankcountry,
        bankcode: bankcode,
        bankbranchcode: bankbranchcode,
        bankswiftcode: bankswiftcode,
        krapin: krapin,
        nhif: nhif,
        onboardingdate: signdate,
        signature: signUniqueId,
        onboardtype: true
      });
      toggleLoader(false);
      form.resetFields();
      if (response.code === "SUCCESS") {
        if (response?.message === "Please Upgrade Your Plan") {
          message.error(response?.message);
        } else {
          message.success(response?.message);
          setStep(0)
          setFormValues({})
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <div>
        <StepsProgress stepdata={stepdata} title="" step={step} />
        {step === 0 && <PersonalForm  setStep ={setStep} setFormValues={setFormValues} formValues={formValues} form={form}/>}
        {step === 1 && <EducationInfo goBack={goBack} setStep ={setStep} setFormValues={setFormValues} formValues={formValues} form={form}/>}
        {step === 2 && <WorkInFormation goBack={goBack} setStep ={setStep} setFormValues={setFormValues} formValues={formValues} form={form}/>}
        {step === 3 && <BankInformation goBack={goBack} setFormValues={setFormValues}  handleSubmit={handleSubmit} formValues={formValues} form={form} showLoader={showLoader} setSignUniqueId={setSignUniqueId}/>}
    </div>
  )
}

export default AddUser