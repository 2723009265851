import React from "react";
// import Icon from "atoms/Icon";
// import { ReadyState } from "react-use-websocket";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DoneAllIcon from "@mui/icons-material/DoneAll";
// import crm from "assets/productlogo/crm.svg";
// import tymeplus from "assets/productlogo/tymeplus.svg";
// import tractask from "assets/productlogo/tractask.svg";
// import myaccount from "assets/productlogo/myaccount.svg";

const ORIGIN = window.location.origin;

const LIVE_SOCKET = `wss://idnj39flhf.execute-api.us-east-2.amazonaws.com/Prod/`;
const TEST_SOCKET = `wss://idnj39flhf.execute-api.us-east-2.amazonaws.com/Prod/`;
const DEV_SOCKET = `wss://idnj39flhf.execute-api.us-east-2.amazonaws.com/Prod/`;
export const SERVICE_URL = `https://gmer55i7g3.execute-api.us-east-1.amazonaws.com/Prod/`;
export const AI_BASE_URL = `https://mvff9fjrsc.execute-api.ap-south-1.amazonaws.com/dev/`;
export const LOGIN_BASE_URL = `https://gmer55i7g3.execute-api.us-east-1.amazonaws.com/Prod/`;

export const AwardList = [
  "Select",
  "Accounting and Auditing Board of Ethiopia",
  "Association of Chartered Certified Accountants",
  "Cisco Certified Network Associate",
  "Certified Ethical Hacker",
  "Chartered Financial Analyst",
  "Chartered Financial Analyst Institute",
  "Certified Human Resource Professional",
  "Certified Investment & Financial Analyst",
  "Chartered Institute of Marketing",
  "Chartered Institute of Procurement & Supply",
  "Certified Information Systems Auditor",
  "Computer Industry and Services Association of Kenya",
  "Certified Information Systems Manager",
  "Chartered Marketing Management Professional",
  "Certified Public Accountant",
  "Certified Public Secretary",
  "Certified Procurement and Supply Professional – Kenya",
  "Institute of Certified Investment & Financial Analysts",
  "Institute of Certified Public Accountants of Kenya",
  "Institute of Certified Public Accountants of Rwanda",
  "Institute of Certified Public Accountants of Uganda",
  "Institute of Certified Secretaries of Kenya",
  "Institute of Directors",
  "Institute of Human Resource Management",
  "Institute of Internal Auditors",
  "Information Systems Audit and Control Association",
  "Microsoft Certified AI Engineer",
  "Microsoft Certified Data Engineer",
  "Microsoft Certified DevOps Engineer",
  "Microsoft Certified Data Scientist",
  "Microsoft Certified Functional Consultant",
  "Microsoft Certified Systems Administrator",
  "Microsoft Certified Solutions Architect",
  "Microsoft Certified Systems Developer",
  "Microsoft Certified Security Engineer",
  "Marketing Society of Kenya",
  "National Board of Accountants and Auditors – Tanzania",
  "Project Management Institute",
  "Project Management Professional",
];

export const SOCKET_URL =
  ORIGIN.indexOf("localhost") > -1
    ? TEST_SOCKET
    : ORIGIN.indexOf("test") > -1
    ? TEST_SOCKET
    : LIVE_SOCKET;

// export const Products = [
//   {
//     name: "Tymeplus",
//     url: "",
//     logo: tymeplus,
//     access: true,
//   },
//   {
//     name: "TracTask",
//     url: `https://www.tractask.waila.co/?token=${window.localStorage.getItem(
//       "token"
//     )}`,
//     logo: tractask,
//     access: true,
//   },
//   {
//     name: "Marketing Tool",
//     url: `https://www.crm.waila.co/?token=${window.localStorage.getItem(
//       "token"
//     )}`,
//     logo: crm,
//     access: true,
//   },
//   {
//     name: "My Account",
//     url: `https://www.myaccount.waila.co/?token=${window.localStorage.getItem(
//       "token"
//     )}`,
//     logo: myaccount,
//     access: true,
//   },
// ];

export const NON_EMPTY_REGEX = /^(?!\s*$).+/;

export const EMAIL_REGEX = /^[a-zA-Z0-9.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const NON_EMPTY_SINGLE_NUMBER = /\b[0-9]\b/;

export const NON_EMPTY_NUMBER = /^\d+$/;

export const NON_CHARACTER = /^[0-9\.\-\+]+$/;

//Validate symbol except space
export const VALIDATE_SYMBOL = /^[^<>*|/\\~#{}()[\],$^%&+=_@'?.!"]+$/g;

export const VALIDATE_CHARACTER = /^[A-Za-z\s]+$/;

export const VALIDATE_SYMBOL_CHARACTER =
  /^[a-zA-Z\s~`!@#$%^&*()_+\-=\[\]{}|;':",.\/<>?\\]*$/;

export const PUNCH_ACTIONS = {
  PUNCH_IN: 1,
  PUNCH_OUT: 2,
  BREAK_START: 3,
  BREAK_END: 4,
};

export const InitalSteps = {
  title: "",
  steps: [{ 0: "New" }],
};

export const STATUS = {
  SUCCESS: "SUCCESS",
  FAIL: "FAIL",
  ERROR: "ERROR",
  RECORD_NOT_FOUND: "RECORD_NOT_FOUND",
};


export const STATUS_CODE = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  INVALID_PAYLOAD: "INVALID_PAYLOAD",
  AUTHENTICATION_FAILED: "AUTHENTICATION_FAILED",
  AUTHENTICATION_SUCCESSFUL: "AUTHENTICATION_SUCCESSFUL",
  USER_DISABLED: "USER_DISABLED",
  VALID_SESSION: "VALID_SESSION",
  SAME_PASSWORD: "SAME_PASSWORD",
  INVALID_CODE: "INVALID_CODE",
  RECORD_NOT_FOUND: "RECORD_NOT_FOUND",
  INVALID_SESSION: "INVALID_SESSION",
  PASSWORD_UPDATED: "PASSWORD_UPDATED",
  RECORD_EXIST: "RECORD_EXIST",
  OUTSIDE_ALLOWED_PROXIMITY: "OUTSIDE_ALLOWED_PROXIMITY",
  PUNCH_IN_SUCCESSFUL: "PUNCH_IN_SUCCESSFUL",
  PUNCH_OUT_SUCCESSFUL: "PUNCH_OUT_SUCCESSFUL",
  BREAK_STARTED: "BREAK_STARTED",
  BREAK_ENDED: "BREAK_ENDED",
  SOMETHING_WENT_WRONG: "SOMETHING_WENT_WRONG",
  TASK_SAVED: "TASK_SAVED",
  TASK_DELETED: "TASK_DELETED",
  LEAVE_EXCEED: "LEAVE_EXCEED",
  ANNUAL_LEAVE_AVAILABLE: "ANNUAL_LEAVE_AVAILABLE",
  SICK_LEAVE_AVAILABLE: "SICK_LEAVE_AVAILABLE",
  REASON: "SEND_REASON",
};

export const LEAVE_INFO = {
  AVAILABLE: "Available",
  CONSUMED: "Consumed",
};

export const USER_REPORT = {
  ONTIME: "OnTime",
  LATE: "Late",
  LEAVE: "Leave",
  EARLYEXIT: "Early Exit",
  ABSENT: "Absent",
};

export const LEAVE_ICON = {
  7: "calendar2",
  1: "medical_box",
  2: "medical_box",
  3: "medical_box",
  5: "pregnant_woman",
  6: "baby_carrier",
};

export const REPORT_DURATION_TYPES = [
  { value: 2, label: "Monthly Report" },
  { value: 1, label: "Custom Date" },
];

export const REPORT_DURATION_TYPES1 = [{ value: 2, label: "Monthly Report" }];

export const APPROVAL_FILTER_TYPES = [
  { value: 4, label: "All" },
  { value: 1, label: "Custom Date" },
  { value: 2, label: "Monthly" },
];

export const CHECKIN_REPORT_FILTER_TYPES = [
  { value: 1, label: "Custom Date" },
  { value: 2, label: "Monthly" },
];
export const MONTH_LIST = [
  { index: 1, value: "January", label: "January" },
  { index: 2, value: "February", label: "February" },
  { index: 3, value: "March", label: "March" },
  { index: 4, value: "April", label: "April" },
  { index: 5, value: "May", label: "May" },
  { index: 6, value: "June", label: "June" },
  { index: 7, value: "July", label: "July" },
  { index: 8, value: "August", label: "August" },
  { index: 9, value: "September", label: "September" },
  { index: 10, value: "October", label: "October" },
  { index: 11, value: "November", label: "November" },
  { index: 12, value: "December", label: "December" },
];

// export const WEB_SOCKET_CONNECTION_STATUS = {
//   [ReadyState.CONNECTING]: "Connecting",
//   [ReadyState.OPEN]: "Open",
//   [ReadyState.CLOSING]: "Closing",
//   [ReadyState.CLOSED]: "Closed",
//   [ReadyState.UNINSTANTIATED]: "Uninstantiated",
// };

export const countryList = [
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, The Democratic Republic of The",
  "Cook Islands",
  "Costa Rica",
  "Cote D'ivoire",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-bissau",
  "Guyana",
  "Haiti",
  "Heard Island and Mcdonald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran, Islamic Republic of",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People's Republic of",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libyan Arab Jamahiriya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia, The Former Yugoslav Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestinian Territory, Occupied",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Pierre and Miquelon",
  "Saint Vincent and The Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and The South Sandwich Islands",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Eswatini",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan (ROC)",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Virgin Islands, British",
  "Virgin Islands, U.S.",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const CompetencyQC = [
  "Audit & Assurance",
  "Tax",
  "Advisory/Consulting",
  "BSO",
];
export const SUPERVISOR = [
  "Sajan SHAH",
  "Zahir SHEIKH",
  "Collins OKANIA",
  "Moses KINYANJUI",
  "Peris MACHARIA",
  "Bryan NGANGA",
  "Peter GITHONGO",
  "Mathew MUMO",
  "Justin WAMBUA",
  "Robert NDUNGU",
  "Nehemia AKONG'O",
  "Kennedy WANDAHI",
  "Fredrick GOGA",
  "Eliud NJOROGE",
  "John GITAHI",
  "Robert MWAURA",
  "Sandeep KHAPRE",
  "Clifford AH CHIP",
  "Magdalene MATHENDU",
  "Justus MOKUA",
  "Steve OKOTH",
  "John KOMU",
  "Daniel MULWA",
  "Susan NYOIKE",
  "Henry SANG",
  "Joseph MWANZANJE",
  "Priscillah GITHANG'A",
  "Roger MUHIA",
  "Maurine KIMNYANGO",
  "Peter MWANGI",
  "Angelique Kantengwa",
  "George Ndungu",
  "Vikash Bunjun",
  "Abel Seid",
  "Agegnehu Tesfa",
  "Alex Kisakye",
  "Alexis Mirimo",
  "Ali Selemani",
  "Amanda Dorothy Nalunkuma",
  "Anna Kakuba",
  "Asterius Rwehikiza",
  "Augustin Muberamfura",
  "Beatrice Mujawayezu",
  "Brenda Ayebare",
  "Catherine Njogu",
  "Cedrick Kibalama",
  "Christopher Kintu Ssenono",
  "Clement Niyitegeka Kabano Egide",
  "Daniel Ishimwe",
  "Duncan Kayiira",
  "Edom Wassie",
  "Emmanuel Habineza",
  "Ephantus Macharia",
  "Eugene Lema",
  "Eunice Mgheni",
  "Fidele Ukwishaka",
  "Francis Ochige",
  "George Otim",
  "George William Ssebalamu",
  "Gerald Bwire",
  "Geta Mehary",
  "Hafiswa Nakanwagi",
  "Harriet Manake",
  "Isaac Kalungi",
  "John Jet Tusabe",
  "Jonathan Fidelis",
  "Judy Mwathi",
  "Juma Buli",
  "Justus Kahumuza",
  "Juvinal Betambira",
  "Kenneth Makanga",
  "Kinoti Roy",
  "Mahuna Mbaga",
  "Marie Rosette Niwewarwego",
  "Martin Kagaba",
  "Mary Nabaggala",
  "Maureen Busingye",
  "Million Kibret",
  "Moses Emuron",
  "Moses Kamande",
  "Moses Lugayizi",
  "Moses Tibamwenda",
  "Mrisho Athumani",
  "Nafkot Fana",
  "Nicholus Mushabenta",
  "Nzioka Muindi",
  "Pascal Ndagijimana",
  "Rose Uroki",
  "Rutta Kabwogi",
  "Samrawit Yemaneh",
  "Seifu Haile",
  "Sheilla Mahoro",
  "Stephen Kihiu",
  "Stephen Muhumuza",
  "Swalehe Madore",
  "Tewahido Samson",
  "Theresa Owomugisha",
  "Tina Belay",
  "Uwase Clarisse",
  "William Nombo",
  "Yusuf Chanyika",
];

export const MANAGER = [
  "Anne MAINA",
  "Benedict OKANIA",
  "Bonareri MOCHAMA",
  "Bryan NGANGA",
  "Collins OKANIA",
  "Daniel MULWA",
  "Eliud NJOROGE",
  "Ephantus MACHARIA",
  "Esther ONYONA",
  "Fredrick GOGA",
  "Irene KENDI",
  "John GITAHI",
  "John KOMU",
  "John NG'ANG'A",
  "Joseph MWANZANJE",
  "Magdalene MATHENDU",
  "Mathew MUMO",
  "Maurine KIMNYANGO",
  "Moses KINYANJUI",
  "Nehemia AKONG'O",
  "Nicholas LANGAT",
  "Paul MACHARIA",
  "Peter KARIUKI",
  "Peter MWANGI",
  "Priscillah GITHANG'A",
  "Robert MWAURA",
  "Roger MUHIA",
  "Sajan SHAH",
  "Soumya VADLAMANI",
  "Stephen MASINGA",
  "Susan GICHURU",
  "Susan NYOIKE",
  "Winnie OTIENO",
];

export const ServiceLine = [
  {
    competency: "Audit & Assurance",
    serviceline: [
      "Audits (IFRS / Statutory Audits)",
      "Non-assurance engagements (AUP + Compilations)",
      "Other assurance engagements (Project Audits)",
      "Reviews (Others)",
    ],
  },
  {
    competency: "Tax",
    serviceline: [
      "Tax advisory Services",
      "Tax Compliance and Assurance Services",
    ],
  },
  {
    competency: "Advisory/Consulting",
    serviceline: [
      "Corporate Finance, Transactions, Restructuring & M&A",
      "Risk Management (RAS), Forensics Investigations, Risk, Regulatory & Compliance",
      "Management Consultancy (Strategy, HR, PM, Devt.Advisory)",
      "Technology Advisory, Cyber security, Analytics & Insights",
    ],
  },
  {
    competency: "BSO",
    serviceline: [
      "Accounting and Payroll Services",
      "Corporate secretarial and administrative services",
      "Business process outsourcing (FMA, BPO and other admin services)",
    ],
  },
];

export const SubServiceLine = [
  "Deal Advisory- Valuation",
  "Deal Advisory- Due Diligence",
  "Deal Advisory- Capital Raise",
  "Deal Advisory- M&A",
  "Risk Advisory- Internal Audit",
  "Risk Advisory- Forensic Services",
  "Risk Advisory- Insolvency",
  "Risk Advisory- Process Consulting",
];

export const CompetencyRole = [
  //? Changed to OfficeCompetencyRole
  "Country Leader- Advisory",
  "Country Leader- Audit",
  "Country Leader- BSO",
  "Country Leader- Data Analyst",
  "Country Leader- Finance",
  "Country Leader- HR",
  "Country Leader- IT",
  "Country Leader- Marketing",
  "Country Leader- Tax",
  "HO- Leader",
  "Managing partner",
  "Member",
  "RCEO",
  "Team Leader- Advisory",
  "Team Leader- Audit",
  "Team Leader- BSO",
  "Team Leader- Tax",
];

export const RegionalCometencyRole = [
  "Backstop",
  "HAAQM",
  "HMR",
  "Regional Leader",
  "Regional Member",
];

export const IndustryRole = [
  "Regional-leader",
  "Country Leader",
  "Office - Leader",
  "Member",
];

export const Grade = [
  "P5",
  "P4",
  "P3",
  "P2",
  "P1",
  "M2",
  "M1",
  "C4",
  "C3",
  "C2",
  "C1",
  "B2",
  "B1",
  "A2",
  "A1",
  "S4",
  "S3",
  "S2",
  "S1",
];

export const CountryCompany = [
  {
    country: "Kenya",
    location: "Nairobi",
  },
  {
    country: "Uganda",
    location: "Kampala",
  },
  {
    country: "Tanzania",
    location: "Dar es Salaam",
  },
  {
    country: "Rwanda",
    location: "Kigali",
  },
  {
    country: "Ethiopia",
    location: "Addis Ababa",
  },
  {
    country: "Burundi",
    location: "Bujumbura",
  },
  {
    country: "DR Congo",
    location: "Kinshasa",
  },
  {
    country: "Head Office",
    location: "Lubumbashi",
  },
  {
    country: "Other",
    location: "Other",
  },
];

export const Degree = [
  "Bachelors - Accounting - Complete",
  "Masters - Economics - Complete",
  "PhD - Accounting - In Progress",
  "MBA - Finance - In Progress",
];

export const Institute = [
  "CPA - Complete",
  "CPA - In progress",
  "CPA - Fellow",
  "etc.",
  "CFA",
  "CISA",
  "CFE",
  "CIA",
  "PMP",
];

export const Greetings = ["Christmas", "Diwali", "Eid", "Chinese NY"];

export const ProfessionalCertification = [
  "CPA - Complete",
  "CPA - In progress",
  "CPA - Fellow",
  "etc.",
  "CFA",
  "CISA",
  "CFE",
  "CIA",
  "PMP",
];

export const Industry = [
  "Agribusiness",
  "Floriculture and Fisheries",
  "Government and Public Sector",
  "Consumer Business (includes Retail, Leisure & Hospitality & Gaming)",
  "Infrastructure",
  "Real Estate and Construction Transport and Logistics",
  "Banking",
  "Insurance and other Financial Services",
  "Manufacturing & Distributions",
  "Technologies",
  "Media and Telecom",
  "Not for Profit",
  "Natural Resources & Energy",
];

export const Sector = [
  "Primary Sector",
  "Secondary Sector 1",
  "Secondary Sector 2",
  "Secondary Sector 3",
];

export const secondaryIndustry = [
  "Banking, Insurance and other Financial Services",
  "Manufacturing & Distributions",
  "Government and Public Sector",
  "Banking, Insurance and other Financial Services",
  "Banking, Insurance and other Financial Services",
  "Technologies, Media and Telecom",
];

export const ContractingCompany = [
  "BDO Advisory Services Limited",
  "BDO Consulting PLC",
  "BDO Africa Holdings Ltd",
  "BDO EA (Rwanda) Ltd",
  "BDO Kenya Limited",
  "DCDM Advisory Services Limited",
];

export const DOCUMENTFOLDER = [
  { label: "Identity Information", value: "identityinformation" },
  { label: "Employment Contract", value: "employmentcontract" },
  { label: "PIP", value: "PIP" },
  { label: "Warnings", value: "warnings" },
  { label: "Commendation Letters", value: "commendationletters" },
  { label: "Performance Contract", value: "performancecontract" },
  { label: "Performance Appraisal", value: "performanceappraisal" },
  { label: "Wow Factor evidence", value: "factorevidence" },
  { label: "Star note", value: "starnote" },
];
