
import React, { useEffect, useRef, useState } from 'react'
import {
    Row,
    Col,
    Form,
    Input,
    DatePicker,
    Select,
    message,
    Checkbox,
    Switch,
    Upload,
    Space,
    TimePicker,
    Button
} from "antd";
import styled from "@emotion/styled";
import moment from "moment";
import {
    NON_EMPTY_REGEX,
    EMAIL_REGEX,
    countryList,
    Greetings,
    AwardList,
} from "common/Constants";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isEmpty } from 'lodash';


const StyledButton = styled(Button)`
  background: #26a4ff;
  border-radius: 5px;
  color: #ffffff;
  width: 100px;
  margin: 10px;
`;

const StyleRow = styled(Row)`
  padding: 10px;
  box-shadow: 0px 3px 6px #00000015;
  .ant-form-item-label {
    text-align: left;
  }
  .ant-picker{
    width: 100% !important;
  }
  .ant-upload.ant-upload-select {
    width: 100% !important;
    color: #397ff4 !important;
    .ant-btn > span {
      display: inline-block;
      color: #397ff4;
    }
    button,
    html [type="button"],
    [type="reset"],
    [type="submit"] {
      background: #f2f2f2;
    }
    .ant-upload-list-picture .ant-upload-list-item,
    .ant-upload-list-picture-card .ant-upload-list-item {
      height: 40px;
    }
    .ant-upload-list-text .ant-upload-list-item-name,
    .ant-upload-list-picture .ant-upload-list-item-name {
      color: #397ff4;
    }
  }
`;

function WorkInFormation({setStep, goBack, setFormValues, formValues, form}) {
    const { Option } = Select;
    const containerRef = useRef(null);
    const onFinish = (values) => {
        const currentFormValues = form.getFieldValue();
        setFormValues((prevValues) => ({
            ...prevValues,
            ...currentFormValues,
          }));
        setStep(3)
        console.log('Received values:', values);
        // Handle API calls or state updates here
    };

    useEffect(()=> {
      if(!isEmpty(formValues)){
        form.setFieldsValue(formValues);
      }
    }, [formValues])

    return (
        <div>
               <Form
              className=""
              form={form}
              name="addemployeeidentityform"
              layout="vertical"
              initialValues={{
                workexperience: [
                  {
                    companyname: "",
                    fromdate: "",
                    todate: "",
                    jobtitle: "",
                    relevnt: "",
                    jobdescription: "",
                  },
                ],
              }}
              onFinish={onFinish}
            >
              <b>Work Experience:</b>
              <Form.List name="workexperience">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <StyleRow gutter={16} className="mt-2">
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            {...restField}
                            label="Company name"
                            name={[name, "companyname"]}
                            rules={[
                              {
                                required: false,
                                message: "Company name required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Company name"
                              // onChange={() => {
                              //   onFinish();
                              // }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            {...restField}
                            label="Job Title"
                            name={[name, "jobtitle"]}
                            rules={[
                              {
                                required: false,
                                message: "Job Title required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Job Title"
                              // onChange={() => {
                              //   onFinish();
                              // }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            {...restField}
                            label="From:"
                            name={[name, "fromdate"]}
                            rules={[
                              { required: false, message: "Date required" },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              // onChange={() => {
                              //   onFinish();
                              // }}
                              placeholder="From"
                              getPopupContainer={() => containerRef.current}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            {...restField}
                            label="To:"
                            name={[name, "todate"]}
                            rules={[
                              { required: false, message: "Date required" },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              // onChange={() => {
                              //   onFinish();
                              // }}
                              placeholder="To"
                              getPopupContainer={() => containerRef.current}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            label="Relevant"
                            name={[name, "relevant"]}
                            rules={[
                              { required: false, message: "Relevant required" },
                            ]}
                          >
                            <Select
                              placeholder="Select"
                              getPopupContainer={() => containerRef.current}
                              // onChange={onFinish}
                            >
                              <Option value="yes">Yes</Option>
                              <Option value="no">No</Option>
                            </Select>
                          </Form.Item>
                          {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            {...restField}
                            label="Job Description"
                            name={[name, "jobdescription"]}
                            rules={[
                              {
                                required: false,
                                message: "Job Description required",
                              },
                            ]}
                          >
                            <Input.TextArea
                              placeholder="Job Description"
                              // onChange={() => {
                              //   onFinish();
                              // }}
                            />
                          </Form.Item>
                          {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                        </Col>
                      </StyleRow>
                    ))}
                    <Form.Item>
                      <u
                        className="bg-transparent border-0 text-start"
                        type="dashed"
                        style={{cursor: "pointer"}}
                        onClick={() => add()}
                        block
                      >
                        +Add Another
                      </u>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <div className="text-end">
                <StyledButton type="primary" onClick={goBack}>
                  Back
                </StyledButton>
                <StyledButton
                  type="primary"
                  htmlType='submit'
                >
                  Next
                </StyledButton>
              </div>
            </Form>
        </div>
    )
}

export default WorkInFormation