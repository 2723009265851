import React, { useEffect, useState } from 'react'
import styled from "@emotion/styled";
import { Row, Col, message } from "antd";
import Recruitment from '../../assets/InsytLogo.svg';
import Bdologo from '../../assets/BDO.svg';

import COLORS from '../../common/Colors';
import AddUser from 'pages/AddUser';


const StyledContainer = styled.div`
  overflow-x: hidden;
  padding-bottom: 5%;
  h5{
    margin-bottom: 10px;
  }
  a{
    color: white;
    text-decoration: none;
    margin-bottom: 20px;
  }
  .anticon-check-circle {
    font-size: 4rem;
    display: block;
    padding: 10px;
    color: #2CFF00;
  }
  h3{
    color: ${COLORS.PRIMARY};
    font-size: 20px;
  }
`;
const StyledRow = styled(Row)`
  /* padding-left: 5%; */
  /* padding-right: 5%; */
  background-color: ${COLORS.SIDEBAR};
  color: #000;

  img {
    max-width: 100%;
    height: auto;
  }
  .page-title {
    color: #000;
    h4{
        color: #000;
    }
    h1 {
      margin-top: 10px;
      color: #000;
      letter-spacing: 2px;
      font-size: calc(3em + 1vw);
    }
    span {
      margin-top: -2%;
    }
  }
`;

function OnboardUser() {



    return (
        <StyledContainer >

            <StyledRow gutter={8} className='px-5'>
                <div className='container'>
                    <div className='mt-5 d-flex gap-4'>
                        <img src={Recruitment} alt="logo" />
                    </div>
                    <Col md={24} sm={24} lg={24} className="page-title">
                        <div className='text-center'>
                            {/* <h4>{jobData?.title}</h4> */}
                            <img src={Bdologo} alt="logo" />
                            <h4 className='mt-2'>Employee Onboarding form</h4>
                        </div>
                    </Col>


                </div>

            </StyledRow>
            <div className='mt-5 container'>
                
                <AddUser/>

            </div>

        </StyledContainer>
    )
}

export default OnboardUser